import SmoothScroll from 'smooth-scroll';
const axios = require('axios').default;
import Glide from '@glidejs/glide'
import SignaturePad from 'signature_pad';
import AOS from 'aos';

AOS.init({
	once: true,
})

require('picturefill')
require('lazysizes')
require('lazysizes/plugins/bgset/ls.bgset')
window.lazySizesConfig = {loadMode: 3}

window.onload = new function(){
	var idanchors = document.querySelectorAll('a[href*="#"]');

	for(let o = 0; o < idanchors.length; o++){
		idanchors[o].addEventListener('click', function(){
			if(this.getAttribute('data-tab')){
				let button = document.querySelector('button[data-tab="' + this.getAttribute('data-tab') + '"]');
				if(button){
					button.click()
				}
			}
		})
	}

	new SmoothScroll('a[href*="#"]')

	// hamburger menu
	const header = document.getElementById('header')
	const main = document.getElementById('main')
	const hamburger = document.getElementById('hamburger')
	const navitems = document.getElementsByClassName('navbar__item')
	var is_sticky = false
	var is_farsticky = false

	if(hamburger && header){
		hamburger.addEventListener('click', function(){
			header.classList.toggle('header--open-nav')
		})
		checkSticky();
		document.addEventListener("scroll", function(e){
			checkSticky()
		});
		for(let i = 0; i < navitems.length; i++){
			navitems[i].addEventListener('click', function(){
				header.classList.remove('header--open-nav')
			})
		}
	}

	function checkSticky(){
		let scrolltop = document.documentElement.scrollTop;
		if(scrolltop > 10){
			if(is_sticky == false){
				header.classList.add('header--sticky')
				main.classList.add('main--sticky')
				is_sticky = true
			}
		}else{
			header.classList.remove('header--sticky')
			main.classList.remove('main--sticky')
			is_sticky = false;
		}

		if(scrolltop > 80){
			if(is_farsticky == false){
				header.classList.add('header--sticky-far')
				main.classList.add('main--sticky-far')
				main.classList.add('main--sticky-far')
				document.getElementById('scroll-up').classList.add('scroll-up--active')
				is_farsticky = true
			}
		}else{
			header.classList.remove('header--sticky-far')
			main.classList.remove('main--sticky-far')
			document.getElementById('scroll-up').classList.remove('scroll-up--active')
			is_farsticky = false;
		}
	}

	// Loading svgs dynamically
	const svgs = document.querySelectorAll('img[data-svg]')

	for(let i = 0; i < svgs.length; i++){
		let url = svgs[i].getAttribute('data-src');
		if(url){
			if(url.indexOf('.svg') != -1){
				axios.get(url).then(function (response) {
					let svgelement = new DOMParser().parseFromString(response.data, "text/xml").documentElement;
					if(svgs[i].getAttribute('height') || svgs[i].getAttribute('width')){
						svgelement.style.height = 'auto';
						svgelement.style.width = 'auto';
						if(svgs[i].height) svgelement.style.height = svgs[i].getAttribute('height');
						if(svgs[i].width) svgelement.style.width = svgs[i].getAttribute('width');
						if(svgs[i].getAttribute('class')) svgelement.classList.add(svgs[i].getAttribute('class'));
					}

					svgs[i].style.display = 'none';
					svgs[i].parentNode.appendChild(svgelement)
				}).catch(function(error){
					svgs[i].src = url;
				});
			}else{
				svgs[i].src = url;
			}
		}
	}

	// Loading starratings dynamically
	const starratings = document.querySelectorAll('div[data-starrating]')

	for(let i = 0; i < starratings.length; i++){
		let rating = parseInt(starratings[i].getAttribute('data-starrating'))
		let stars = starratings[i].children;
		if(rating){
			rating /= 2;
			for(let o = 0; o < 5; o++){
				if((o + 1) <= rating){
					stars[o].firstElementChild.classList.add('d-none');
					stars[o].firstElementChild.classList.add('d-none');
				}else{
					if((o + 1) > rating && ((o + 1) - rating) == 0.5){
						stars[o].lastElementChild.classList.add('d-none')
						stars[o].lastElementChild.classList.add('d-none')
					}else{
						stars[o].firstElementChild.classList.add('d-none');
						stars[o].firstElementChild.classList.add('d-none');
						stars[o].lastElementChild.classList.add('d-none');
						stars[o].lastElementChild.classList.add('d-none');
					}
				}
				
			}
		}
	}

	//herobannerslider
	const reviewslider = document.getElementsByClassName('review-slider')
	if(reviewslider.length > 0){
		var slides = document.querySelectorAll('.review-slider .glide__slide')
		if(slides.length > 1){
			new Glide(reviewslider[0], {
				type: 'carousel',
				animationDuration: 500,
				animationTimingFunc: 'ease-in-out',
				autoplay: 8000,
			}).mount()
		}
	}

	const werkwijzeslider = document.getElementsByClassName('werkwijze-slider')
	if(werkwijzeslider.length > 0){
		var slides = document.querySelectorAll('.werkwijze-slider .glide__slide')
		if(slides.length > 1){
			new Glide(werkwijzeslider[0], {
				type: 'carousel',
				animationDuration: 500,
				animationTimingFunc: 'ease-in-out',
				autoplay: 8000,
			}).mount()
		}
	}

	const companyslider = document.getElementsByClassName('company-slider')
	if(companyslider.length > 0){
		var slides = document.querySelectorAll('.company-slider .glide__slide')
		if(slides.length > 1){
			new Glide(companyslider[0], {
				type: 'carousel',
				animationTimingFunc: 'ease-in-out',
				perView: 3,
				breakpoints: {
					961: {
						perView: 2
					},
					380: {
						perView: 1
					},
				}
			}).mount()
		}

		//glide force same height
		setTimeout(function(){
			var companyitems = document.getElementsByClassName('company-item')
			var maxheight = 0;
			for(let i = 0; i < companyitems.length; i++){
				let currentheight = companyitems[i].offsetHeight;
				if(currentheight > maxheight) maxheight = currentheight
			}

			for(let i = 0; i < companyitems.length; i++){
				companyitems[i].style.height = maxheight + 'px';
			}
		}, 150)
	}

	//herobannerslider
	const werkwijze2slider = document.getElementsByClassName('werkwijze2-slider')
	if(werkwijze2slider.length > 0){
		var slides = document.querySelectorAll('.werkwijze-slider .glide__slide')
		if(slides.length > 1){
			new Glide(werkwijze2slider[0], {
				type: 'carousel',
				perView: 3,
				animationDuration: 500,
				animationTimingFunc: 'ease-in-out',
				autoplay: 5000,
				breakpoints: {
					960: {
						perView: 2
					},
					380: {
						perView: 1
					},
				}
			}).mount()

		}
	}

	//herobannerslider
	const advantagesslider = document.getElementsByClassName('advantages-slider')
	if(advantagesslider.length > 0){
		var advantagesitems = document.getElementsByClassName('advantage-item');
		var slides = document.querySelectorAll('.advantages-slider .glide__slide')
		if(slides.length > 1){
			let advantagesglide = new Glide(advantagesslider[0], {
				type: 'carousel',
				perView: 7,
				focusAt: 'center',
				animationDuration: 500,
				animationTimingFunc: 'ease-in-out',
				breakpoints: {
					960: {
						perView: 5
					},
					480: {
						perView: 3
					},
				}
			}).mount()

			for(let i = 0; i < advantagesitems.length; i++){
				advantagesitems[i].addEventListener('click', function(){
					advantagesglide.go('=' + advantagesitems[i].getAttribute('data-index'))
				})
			}

			let advantagestextslider = document.getElementsByClassName('advantages-textslider__item')

			advantagesglide.on('run', (e) => {
				for(let i = 0; i < advantagestextslider.length; i++){
					if(i != advantagesglide.index){
						advantagestextslider[i].classList.remove('advantages-textslider__item--active')
					}else{
						advantagestextslider[i].classList.add('advantages-textslider__item--active')
					}
				}
			})
		}
	}

	// Tabs in investering (sticky)
	const tabs = document.getElementsByClassName('tabs')
	for(let i = 0; i < tabs.length; i++){
		let tabsparent = tabs[i].parentElement;
		let tabitems = tabsparent.querySelectorAll('.tabs__item')
		let tabbuttons = tabsparent.querySelectorAll('.tab-button')

		for(let o = 0; o < tabbuttons.length; o++){
			tabbuttons[o].addEventListener('click', function(){
				for(let u = 0; u < tabitems.length; u++){
					if(this.getAttribute('data-tab') == tabitems[u].id){
						tabitems[u].classList.add('tabs__item--active')
					}else{
						tabitems[u].classList.remove('tabs__item--active')
					}
				}

				for(let u = 0; u < tabbuttons.length; u++){
					if(this.getAttribute('data-tab') == tabbuttons[u].getAttribute('data-tab')){
						tabbuttons[u].classList.add('tab-button--active')
					}else{
						tabbuttons[u].classList.remove('tab-button--active')
					}
				}

				this.classList.add('tab-button--active')

				document.getElementById('investering').scrollIntoView({
					behavior: 'smooth'
				});
			})
		}
	}

	// Popup panels
	const paneltriggers = document.querySelectorAll('*[data-panel]')
	for(let i = 0; i < paneltriggers.length; i++){
		let paneltotrigger = paneltriggers[i].getAttribute('data-panel');

		if(paneltotrigger){
			let panel = document.getElementById(paneltotrigger);
			paneltriggers[i].addEventListener('click', function(){
				if(panel){
					panel.classList.toggle('panel--active')
				}
			})

			document.querySelector('#' + paneltotrigger + ' .panel__close').addEventListener('click', function(){
				panel.classList.remove('panel--active')
			})

			document.querySelector('#' + paneltotrigger + ' .panel__backlayer').addEventListener('click', function(){
				panel.classList.remove('panel--active')
			})
		}
	}

	// Signature stuff, https://github.com/szimek/signature_pad
	const canvas = document.getElementById('signaturepad')
	if(canvas){
		let signaturePad = new SignaturePad(canvas);
		const signatureclear = document.getElementById('signaturepad-clear')
		signatureclear.addEventListener('click', function(e){
			e.preventDefault();
			signaturePad.clear()
		})
	}

	// Accordion
	const accordions = document.getElementsByClassName('accordion')
	if(accordions){
		for(let i = 0; i < accordions.length; i++){
			accordions[i].querySelector('.accordion__header').addEventListener('click', function(e){
				accordions[i].classList.toggle('accordion--active')
			})
		}
		
	}
}